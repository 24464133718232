import styled from 'styled-components'
import { Text, Link } from 'rebass/styled-components'
import Fade from 'react-reveal/Fade'
import makeStyles from "@material-ui/core/styles/makeStyles"
import * as React from "react"
import { defaultTheme } from "../styles/colors"

const TextFooter = styled(Text)`
  color: ${(props) => props.theme.colors.background};

  & a {
    color: ${(props) => props.theme.colors.background};
    transition: color ease 0.5s;

    &:hover {
      color: ${(props) => props.theme.colors.primaryLight};
    }
  }
`;

const useStyles = makeStyles(() => ({
  footerContainer: {
    backgroundColor: defaultTheme.colors.primaryDark,
    padding: '16px',
    color: defaultTheme.colors.background
  }
}))

const Footer = () => (
    <div className={useStyles().footerContainer}>
      <Fade left>
        <TextFooter fontSize='3'>
          <span>{`BurritoApps - Powered by `}</span>
          <Link href="https://www.gatsbyjs.org/">Gatsby</Link>
          <span> and </span>
          <Link href="https://www.netlify.com/">
            Netlify
          </Link>
        </TextFooter>
      </Fade>
    </div>
);

export default Footer;
